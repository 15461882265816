import { ChainId, SUPPORTED_CHAINS, SupportedChainsType } from './chains'

type AddressMap = { [chainId: number]: string }

type ChainAddresses = {
  factoryAddress: string
  multicallAddress: string
  quoterAddress: string
  orderBookAddress?: string
  nonfungiblePositionManagerAddress?: string
  tickLensAddress?: string
  swapRouterAddress?: string
  v1MixedRouteQuoterAddress?: string
  lockAddress?: string
  tradeRouter?: string
  voterAddress?: string
  rougexTokenAddress?: string
  faucetAddress?: string
  minterAddress?: string
  tradeReaderAddress?: string
  rewardsDistributorAddress?: string
  roxUtilsAddress?: string
}

const DEFAULT_NETWORKS = [ChainId.ARBITRUM_ONE]

function constructSameAddressMap(address: string, additionalNetworks: ChainId[] = []): AddressMap {
  return DEFAULT_NETWORKS.concat(additionalNetworks).reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = address
    return memo
  }, {})
}
/*
   "rougeX" : "0xdDc35Df5121ADf6eAAeeD369e42bca0C526245DF",
    "RoguexFactory" : "0x70FE55a4C89f5FdC2598878C8Bd62764b1038283",
    "RoxUtils" : "0x586536752bD0d3D767440E73FdBa2D4016990E2F",
    "RoxSpotPoolDeployer" : "0x6C3a9Fd1ce91a1a0707271bBBd9339209ad82Af4",
    "RoxPerpPoolDeployer" : "0x5d9fE23c874782910ae44B56400e6A841d023380",
    "RoxPosnPoolDeployer" : "0x01593B8AaaDc5238F2f5C8597bC1402eB7a48c5f",
    "NonfungiblePositionManager" : "0x5Cf3679187B6096a990393bBE67Ec191e3fD7416",
    "Quoter" : "0xA13f5A14c384F550c7168453c1EE0B30F10dc4d0",
    "TickLens" : "0xE58E92B0A47F29c38A919527099B4d5f7eff6d43",
    "PerpRouter" : "0xBCcDa22DB89AA35Eff735c433AE0f2642dd3Da07",
    "SwapRouter" : "0x064d6286b4A9702a85e934AECac2407eb509F34a",
    "PerpOrderbook" : "0x9D0DF48c859942cFd437F1A5e8e5f8980118e445",
    "VeArtProxy" : "0xb1a8afcbf2c0c43332CAbeDbBBD45D027354553d",
    "VotingEscrow" : "0xCD3B42917b4d1f896F0735C1D6EDd44304548Fa4",
    "RewardsDistributor" : "0x0d0E982AE77DE7bcd59f9Bd9bf19BBa6d2068964",
    "MasterChefFactory" : "0x7e9bC6005bD9baBA2604a6E280111bBd2FD2Ed1E",
    "SwapMinningFactory" : "0xc0D575d2CaAE78F6938b67501f864aeD87f160dE",
    "VotingRewardFactory" : "0xe54bE52C266C857F575d77A029CCA3E2eFD28442",
    "Voter" : "0x03534749642F4545233CCFDA3c1074fDc8a5511e",
    "Minter" : "0xF73e7422fBBdB4CB4252D0eF3724D9eb6967Aca3",
    "DisFeeReward" : "0xf27547BB6e5b7D2377D1DF943213CB4ff6Ba2587",
    "HypervisorFactory" : "0xa7D0b2c3Dfc8315Fa353a91904acD1c71F55B573",
    "Reader" : "0xE48f2C155e4Cc7D7d2aFBEEc11ce85088e7d5D90",
*/
const ARBITRUM_ONE_ADDRESSES: ChainAddresses = {
  factoryAddress: '0x70FE55a4C89f5FdC2598878C8Bd62764b1038283',
  tradeRouter: '0xBCcDa22DB89AA35Eff735c433AE0f2642dd3Da07',
  nonfungiblePositionManagerAddress: '0x5Cf3679187B6096a990393bBE67Ec191e3fD7416',
  swapRouterAddress: '0x064d6286b4A9702a85e934AECac2407eb509F34a',
  quoterAddress: '0xA13f5A14c384F550c7168453c1EE0B30F10dc4d0',
  tickLensAddress: '0xE58E92B0A47F29c38A919527099B4d5f7eff6d43',

  roxUtilsAddress: '0x586536752bD0d3D767440E73FdBa2D4016990E2F',
  tradeReaderAddress: '0xE48f2C155e4Cc7D7d2aFBEEc11ce85088e7d5D90',
  orderBookAddress: '0x9D0DF48c859942cFd437F1A5e8e5f8980118e445',

  lockAddress: '0xCD3B42917b4d1f896F0735C1D6EDd44304548Fa4',

  rougexTokenAddress: '0xdDc35Df5121ADf6eAAeeD369e42bca0C526245DF',
  rewardsDistributorAddress: '0x0d0E982AE77DE7bcd59f9Bd9bf19BBa6d2068964',

  voterAddress: '0x03534749642F4545233CCFDA3c1074fDc8a5511e',
  minterAddress: '0xF73e7422fBBdB4CB4252D0eF3724D9eb6967Aca3',

  multicallAddress: '0x027EDCF231957833e95Bf6131E61EA8345d580d3',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}

// eslint-disable-next-line multiline-comment-style
// arbitrum goerli v3 addresses
const GOERLI_ADDRESSES: ChainAddresses = {
  factoryAddress : '0x0149Ae85D16f396A8cc34445876a304097910639',
  tradeRouter: '0xe32A514D929Daa0A9b3853C71bD0e22d80679CBA',
  nonfungiblePositionManagerAddress : '0xDC3e6cA428F36EEfBb6eE2c40AD2E84FDEe2Db19',
  swapRouterAddress : '0x63238B8e910288f6aB3C8Eebef207d0C7b12b66e',
  quoterAddress : '0x4082541102D020f5aC0a9677E2d7dcAa0BcCB631',
  tickLensAddress : '0x6f91826dAb2aFFD602A97EFeAc8Ba99FA9226062',
  roxUtilsAddress : '0xb92C858f8208C82bE209d347b378BF7d63eBb15F',
  tradeReaderAddress: '0xD4f02851F730e8Ad23560C8d02236E7904885D43',
  orderBookAddress: '0xd95E11E8288F94D5E7eF14DB3f7a7F4D85b5b6fD',

  multicallAddress: '0x1F98415757620B543A52E61c46B32eB19261F984',

  lockAddress: '0x47Cd8AAae6AaDa375AB712bD2340aBD05d403566',
  rougexTokenAddress: '0x3d3fADCbeba5e68a35581681b6a2D650DAd292f4',
  rewardsDistributorAddress: '0x7F80E24A4C482584605c592908fe3278732F5838',

  voterAddress: '0x86395AB62F130E77D1CF237C6d6EBa8F6A7DDc3d',
  minterAddress: '0x55DF366DAb41B8207c7060b9ae67D257bB9A655D',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}

const Sepolia_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xa55F2744DA15cD95822E8e0958305C58e32F9a98',
  tradeRouter: '0xE84594F5B8740E428405f6928C493ef63A318dbA',
  nonfungiblePositionManagerAddress: '0x0Fe7ACf5a758144536e415540e560650B79F6214',
  swapRouterAddress: '0xf8D61E10845893c12B9C495e01976C43F957b529',
  quoterAddress: '0x86eFaA010f65B17dD7504fA7450d56aEcC77bcAD',
  tickLensAddress: '0x4Eb761FCdEC79825b50d7572AdC02601b453639b',
  roxUtilsAddress: '0x75B801637DBB14E59B6Bf378b558601B7B219057',
  tradeReaderAddress: '0xF18F2234823130C50EeD4736893a328Ab4ab54D0',
  orderBookAddress: '0x06422f49ab551c6D7e1ad30af4B7A80f5712d13d',

  multicallAddress: '0x532a5B1A2F8ccE4f05F8b2a9A262c7097B4356ec',

  lockAddress: '0x48878653673113bD4DbEd485a34E194735BA7E10',
  rougexTokenAddress: '0x00fA700a16eF7B3c968Bb1dCD4C6bA697bA73fb9',
  rewardsDistributorAddress: '0x20B7ab1AD1517Bd17cD2F4dc5C29920Bff92ba82',

  voterAddress: '0x780A9Fa3B434b99A2f7751B00c6fC269f19871Bd',
  minterAddress: '0x918891120A62B0F2a91012a8De9f10Dc30a9E3Cd',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}

const CHAIN_TO_ADDRESSES_MAP: Record<SupportedChainsType, ChainAddresses> = {
  [ChainId.ARBITRUM_ONE]: ARBITRUM_ONE_ADDRESSES,
  [ChainId.GOERLI]: GOERLI_ADDRESSES,
  [ChainId.ARBITRUM_SEPOLIA]: Sepolia_ADDRESSES,
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].factoryAddress
    return memo
  }, {}),
}

export const MULTICALL_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].multicallAddress
    return memo
  }, {}),
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {}

export const QUOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].quoterAddress
    return memo
  }, {}),
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const nonfungiblePositionManagerAddress = CHAIN_TO_ADDRESSES_MAP[chainId].nonfungiblePositionManagerAddress
    if (nonfungiblePositionManagerAddress) {
      memo[chainId] = nonfungiblePositionManagerAddress
    }
    return memo
  }, {}),
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'),
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tickLensAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tickLensAddress
    if (tickLensAddress) {
      memo[chainId] = tickLensAddress
    }
    return memo
  }, {}),
}
export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const swapRouter02Address = CHAIN_TO_ADDRESSES_MAP[chainId].swapRouterAddress
    if (swapRouter02Address) {
      memo[chainId] = swapRouter02Address
    }
    return memo
  }, {}),
}
export const TRADE_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeRouter = CHAIN_TO_ADDRESSES_MAP[chainId].tradeRouter
    if (tradeRouter) {
      memo[chainId] = tradeRouter
    }
    return memo
  }, {}),
}

export const LOCK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const lockAddress = CHAIN_TO_ADDRESSES_MAP[chainId].lockAddress
    if (lockAddress) {
      memo[chainId] = lockAddress
    }
    return memo
  }, {}),
}

export const VOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const voterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].voterAddress
    if (voterAddress) {
      memo[chainId] = voterAddress
    }
    return memo
  }, {}),
}

export const MINTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const minterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].minterAddress
    if (minterAddress) {
      memo[chainId] = minterAddress
    }
    return memo
  }, {}),
}

export const ROUGEX_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rougexTokenAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rougexTokenAddress
    if (rougexTokenAddress) {
      memo[chainId] = rougexTokenAddress
    }
    return memo
  }, {}),
}

export const FAUCET_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const faucetAddress = CHAIN_TO_ADDRESSES_MAP[chainId].faucetAddress
    if (faucetAddress) {
      memo[chainId] = faucetAddress
    }
    return memo
  }, {}),
}
export const TRADEREADER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeReaderAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tradeReaderAddress
    if (tradeReaderAddress) {
      memo[chainId] = tradeReaderAddress
    }
    return memo
  }, {}),
}

export const REWARDS_DISTRIBUTOR_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rewardsDistributorAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rewardsDistributorAddress
    if (rewardsDistributorAddress) {
      memo[chainId] = rewardsDistributorAddress
    }
    return memo
  }, {}),
}

export const ROX_UTILS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const roxUtilsAddress = CHAIN_TO_ADDRESSES_MAP[chainId].roxUtilsAddress
    if (roxUtilsAddress) {
      memo[chainId] = roxUtilsAddress
    }
    return memo
  }, {}),
}

export const ORDER_BOOK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].orderBookAddress
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}
