import '@rainbow-me/rainbowkit/styles.css'

import { getDefaultWallets } from '@rainbow-me/rainbowkit'
import { Chain, configureChains, createConfig } from 'wagmi'
import { arbitrum, goerli } from 'wagmi/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy'

const SepoliaTest: Chain = {
  id: 421614,
  network: 'ARBITRUM_SEPOLIA Testnet',
  name: 'ARBITRUM_SEPOLIA Testnet',
  nativeCurrency: {
    name: 'ARBITRUM_SEPOLIA Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    infura: {
      http: ['https://arbitrum-sepolia.infura.io/v3'],
    },
    default: {
      http: ['https://sepolia-rollup.arbitrum.io/rpc'],
    },
    public: {
      http: ['https://sepolia-rollup.arbitrum.io/rpc'],
    },
  },
  testnet: true,
}

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [arbitrum],
  [alchemyProvider({ apiKey: 'bBUosx2q8Pm3dJKBkoxKxPoW5JwPNFDy' })]
  // [infuraProvider({ apiKey: 'f7d803169aa248f28a510d0d1889951c' })]
)

const { connectors } = getDefaultWallets({
  appName: 'ROGUEX',
  projectId: 'd9e02d8e4a4bb8fe574b63430fa1cf34',
  chains,
})

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
})

export const chain = chains
